<template>
  <div class="list_container">
    <div class="header">
      <div class="day">
        <div>{{ jsonDay[dateIndex].year }}年{{ jsonDay[dateIndex].month }}月</div>
        <!-- 按类型查询 -->
        <!-- <div class="tab">
          <span v-for="(item, index) in tabList"
                :class='currentIndex==index?"active":""'
                :key="index"
                @click="changeTab(index)">{{item}}</span>
        </div> -->
      </div>
      <div class="date" v-show="currentIndex == 0">
        <div class="item" v-for="(item, index) in jsonDay" :key="index">
          <div>{{ item.week }}</div>
        </div>
      </div>
      <div class="date" v-show="currentIndex == 0">
        <div class="item" :class='tabIndex == index ? "checked" : ""' :style='item.status === "1" ? "" : "color: #666;"'
          v-for="(item, index) in doctorDate" :key="index" @click="choose($event, index)" :id="index">
          <div>{{ item.day }}</div>
          <div style="font-size: 15px;">{{ item.status === "1" ? "有号" : "无号" }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="timeSlot" v-show="currentIndex == 0">
      <div>已选：{{ timeSlot }}</div>
      <div v-if="deptName !== ''">科室：{{ deptName }}</div>
    </div>
    <div class="list">
      <!-- 按日期查的号源 -->
      <div class="item" v-show="currentIndex == 0" v-for="item in doctorList" :key="item.doctorCode"
        @click="navDoctor(item.doctorCode, item.deptCode, timeSlot, item.doctorName, item.doctorTitle, item.pictureUrl, item.doctorIntrodution)">
        <div class="img">
          <van-image width="90" height="120" :src="item.pictureUrl" fit="contain" v-if="item.pictureUrl" />
          <van-image width="90" height="120" src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png" v-if="!item.pictureUrl"
            fit="contain" />
        </div>

        <div class="content">
          <div class="name">{{ item.doctorName }} <span>{{ item.doctorTitle }}</span> </div>
          <div class="dept">科室:{{ item.deptName }}</div>
          <div class="reg">诊查费：{{ item.regFee }}元</div>

        </div>
        <van-icon name="arrow" class="right" />
        <van-tag class="tags" v-if="item.residueNum > 0" type="primary" plain size="large">余号：{{ item.residueNum
        }}</van-tag>
        <van-tag class="tags" v-else type="danger" plain size="large">号满</van-tag>
      </div>
      <!-- 按医生挂号 -->
      <div class="item" v-show="currentIndex == 1" v-for="item in allDoctor" :key="item.doctorCode"
        @click="navDoctor(item.doctorCode, item.deptCode, item.doctorName)">
        <div class="img">
          <!-- <van-image width="90"
                     height="120"
                     :src="item.pictureUrl"
                     fit="contain"
                     v-if="item.pictureUrl" /> -->
          <van-image width="90" height="120" src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png" fit="contain" />
        </div>

        <div class="content">
          <div class="name">{{ item.doctorName }} <span>{{ item.doctorTitle }}</span> </div>
          <div class="dept">科室:{{ item.deptName }}</div>
          <div class="reg">诊查费：{{ item.regFee }}元</div>

        </div>
        <van-icon name="arrow" class="right" />

      </div>
    </div>
    <van-empty description="暂无内容"
      v-if="(doctorList === '' && currentIndex == 0) || (allDoctor === '' && currentIndex == 1)" />
    <div class="bottom">
      <van-button type="info" style="width: 100%;" @click="navHome">首&nbsp;&nbsp;页</van-button>
      <!-- <van-button type="primary"
                  @click="back">返&nbsp;&nbsp;回</van-button> -->
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "List",
  data () {
    return {
      deptCode: "",
      timeSlot: "",
      jsonDay: [],
      dateIndex: 0,
      doctorList: "",
      deptName: "",
      tabList: ['按日期挂号', '按医生挂号'],
      currentIndex: 0,
      end: '',
      doctorDate: [],
      allDoctor: [],
      tabIndex: 0,
      levelCode: ''
    };
  },
  created () {
    this.deptCode = this.$route.query.deptCode;
    this.levelCode = this.$route.query.levelCode;
    this.getToday();
    this.getDay();
    this.getDoctorList();
    this.end = this.formatTime(+6);
    this.getDoctorDay()
    this.getAllDoctor()
  },
  methods: {
    changeTab (index) {
      this.currentIndex = index
    },
    // 获取日期数据
    async getDoctorDay () {
      this.allDoctor = ''
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        deptCode: this.deptCode,
        beginDate: this.timeSlot,
        endDate: this.end,
        levelCode: this.levelCode

      });
      const { data: res } = await jsonPost("/wxapp/his/dateschedulelist", data);
      if (res.code == 0) {
        this.doctorDate = res.data.item

        for (let i = 0; i <= 6; i++) {
          this.doctorDate[i].day = this.doctorDate[i].scheduleDate.split("-")[2];
        }
      }
    },
    // 按医生挂号
    async getAllDoctor () {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        deptCode: this.deptCode,
        beginDate: this.timeSlot,
        levelCode: this.levelCode
      });
      const { data: res } = await formPost("/wxapp/his/getStaff", data);
      if (res.code == 0) {
        this.allDoctor = res.data.item
      }
    },
    //获取医生排版数据
    async getDoctorList () {
      this.doctorList = [];
      let temp = [];
      let list = [];
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        deptCode: this.deptCode,
        date: this.timeSlot,
        levelCode: this.levelCode
      });
      const { data: res } = await jsonPost("/wxapp/his/getRegList", data);
      this.$toast.clear();
      if (res.code === 0) {
        list = res.data.item;
        for (let i = 0; i < list.length; i++) {
          if (list[i].workStatus === "1") {
            temp.push(list[i]);
          }
        }
        let m = new Map();
        let arr = [];
        let isAdd = true;
        for (let i = 0; i < temp.length; i++) {
          isAdd = true;
          let mapItem = m.get(temp[i].doctorCode);
          if (mapItem !== undefined) {
            temp[i].residueNum =
              parseInt(mapItem.residueNum) + parseInt(temp[i].residueNum);
            isAdd = false;
          }
          if (isAdd) {
            arr.push(temp[i].doctorCode);
          }
          m.set(temp[i].doctorCode, temp[i]);
        }
        for (let i = 0; i < arr.length; i++) {
          let item = m.get(arr[i]);
          this.doctorList.push(item);
        }
        this.deptName = this.doctorList[0].deptName;
      } else {
        this.doctorList = "";
        // this.$toast.fail(res.msg);
      }
    },
    getToday () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + "-" + month + "-" + strDate;
      this.timeSlot = currentdate;
    },
    getDay () {
      let arr = [];
      let day = [];
      let week = [];
      for (let i = 0; i <= 6; i++) {
        day.push(this.formatTime(i));
        week.push(this.formatWeek(this.formatTime(i)));
      }
      for (let i = 0; i < day.length; i++) {
        let str = day[i];
        let s = str.split("-");
        let json = {};
        for (let j = 0; j < s.length; j++) {
          json.year = s[0];
          json.month = s[1];
          json.day = s[2];
        }
        arr.push(json);
      }
      for (let i = 0; i < week.length; i++) {
        arr[i].week = week[i];
      }
      for (let i = 0; i < arr.length; i++) {
        arr[i].checked = false;
      }
      arr[0].checked = true;
      this.jsonDay = arr;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let myDate = new Date(ShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      return y + "-" + m + "-" + d;
    },
    formatWeek (date) {
      let weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = weekArray[new Date(date).getDay()];
      return week;
    },
    choose (e, indexs) {
      this.tabIndex = indexs
      let list = this.jsonDay;
      for (let i = 0; i < list.length; i++) {
        list[i].checked = false;
      }
      let index = parseInt(e.currentTarget.id);
      let item = this.jsonDay[index];
      this.dateIndex = index;
      item.checked = !item.checked;
      this.timeSlot =
        this.jsonDay[index].year +
        "-" +
        this.jsonDay[index].month +
        "-" +
        this.jsonDay[index].day;
      this.getDoctorList();
    },
    navDoctor (doctorCode, deptCode, timeSlot, doctorName, doctorTitle, pictureUrl, doctorIntrodution) {
      this.$router.push({
        path: "/register/type",
        query: {
          dateIndex: this.dateIndex,
          doctorCode: doctorCode,
          deptCode,
          tabIndex: this.tabIndex,
          timeSlot,
          deptName: this.deptName,
          doctorName,
          doctorTitle,
          pictureUrl,
          doctorIntrodution
        }
      });
    },
    navHome () {
      this.$router.push({
        path: "/"
      });
    },
    back () {
      this.$router.push({
        path: "/register"
      });
    }
  }
};
</script>

<style  lang="less" scope>
.list_container .header {
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
  background-color: white;
  z-index: 1;
}

.list_container .header .day {
  text-align: center;
  // display: flex;
  // justify-content: space-between;
  margin-top: 20px;
  color: #666;
  font-size: 46px;
  padding: 0 20px;

  .tab {
    margin-top: 5px;
    font-size: 32px;

    .active {
      background-color: #0252d9;
      color: #f4f4f4;
    }

    span {
      border: 1px solid #0252d9;
      padding: 15px 15px;
    }
  }
}

.list_container .header .date {
  display: flex;
  flex: 1;
}

.list_container .header .date .item {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 40px;
  line-height: 60px;

  .gray {
    color: #666;
  }
}

.list_container .header .date .checked {
  background-color: #0252d9;
  color: white !important;
  border-radius: 20px;
}

.list_container .timeSlot {
  padding: 20px 30px;
  font-size: 40px;
  line-height: 70px;
  border-bottom: 1px solid #f5f5f5;
}

.list_container .line {
  height: 10px;
  background-color: #f4f4f4;
}

.list_container .list {
  padding-bottom: 230px;
}

.list_container .list .item {
  position: relative;
  font-size: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
}

.list_container .list .item .content {
  position: relative;
  margin-left: 30px;
}

.list_container .list .item .content span {
  font-size: 26px;
}

.list_container .list .item .content div {
  margin: 15px 0;
  margin-right: 30px;
}

.list_container .list .item .right {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 40px;
}

.list_container .bottom {
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 100%;
  height: 150px;
  background-color: white;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px;
}

.list_container .bottom .van-button {
  font-size: 40px;
}

.item .tags {
  position: absolute;
  top: 40px;
  right: 70px;
}
</style>
